@import "../../../data/styles.css";

.nav-container {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 3vh;
	z-index: 999;
}

.nav-background {
	width: 420px;
	height: 40px;
	padding-left: 0%;
	padding-right: 0%;
	background: var(--nav-background);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 40px;
	transition: background-color 0.3s ease;
}

.nav-list {
	display: flex;
	justify-content: space-between;
	list-style: none;
	align-items: center;
	margin-left: -16px;
	margin-right: 20px;
	margin-top: 11px;
}

.nav-item {
	font-weight: bold;
	font-size: 80%;
}

.nav-item.active a {
	color: var(--link-color) !important;
}

.nav-item a {
	text-decoration: none;
	color: var(--primary-color);
	transition: color 0.3s ease;
}

.nav-item a:hover {
	color: var(--link-color);
}

.theme-toggle {
	display: flex;
	align-items: center;
}

.theme-toggle-button {
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;
	color: var(--primary-color);
	transition: color 0.3s ease;
}

.theme-toggle-button:hover {
	color: var(--link-color);
}

@media (max-width: 600px) {
	.navbar {
		margin-left: 25%;
		margin-right: 25%;
		width: 80%;
		font-size: 76%;
	}

	.nav-background {
		width: 100%;
		height: 40px;
	}

	.nav-item {
		font-weight: bold;
	}
	.nav-list {
		display: flex;
		justify-content: space-between;
		list-style: none;
		align-items: center;
	}
}