:root {
	/* Light theme colors */
	--primary-color: #27272a;
	--secondary-color: #65656d;
	--tertiary-color: #acacb4;
	--quaternary-color: #e4e4e7;
	--link-color: #14b8a6;
	--background-color: #ffffff;
	--nav-background: #ffffff;
}

[data-theme="dark"] {
	/* Dark theme colors */
	--primary-color: #e4e4e7;
	--secondary-color: #a1a1aa;
	--tertiary-color: #52525b;
	--quaternary-color: #3f3f46;
	--link-color: #2dd4bf;
	--background-color: #18181b;
	--nav-background: #27272a;
}