@import "../../../data/styles.css";

.homepage-article {
	width: 95%;
	max-width: 600px; 
	margin: 20px auto; 
	border-radius: 20px;
	opacity: 0.8;
	transition: background 0.2s ease-in-out;
}

.homepage-article:hover {
	background: var(--bg-secondary);
	opacity: 1;
}

.homepage-article-content {
	padding: 20px; 
}

.homepage-article-date {
	color: var(--tertiary-color);
	font-size: 14px; 
	margin-bottom: 0.5em; 
}

.homepage-article-title {
	color: var(--primary-color);
	font-size: 20px; /* Increased font size for better visibility */
	margin-bottom: 0.5em;
	font-weight: 700;
}

.homepage-article-description {
	color: var(--secondary-color);
	font-size: 14px; /* Increased font size for better readability */
	line-height: 1.6; /* Improved line height for readability */
	margin-bottom: 1em; /* Added margin for spacing */
}

.homepage-article-link {
	padding-top: 10px; /* Reduced padding for smaller devices */
	font-size: 16px; /* Increased font size for better visibility */
	font-weight: 700;
}

.homepage-article-link a {
	color: var(--link-color);
	text-decoration: none;
}

.link-icon {
	font-size: 12px; /* Increased icon size for better visibility */
}

/* Responsive Design */
@media (max-width: 600px) {
	.homepage-article {
		width: 90%; /* Adjust width for smaller screens */
	}
	.homepage-article-content {
		padding: 15px; /* Adjust padding for smaller screens */
	}
	.homepage-article-title {
		font-size: 18px; /* Slightly smaller title on small screens */
	}
	.homepage-article-description {
		font-size: 12px; /* Smaller description for mobile */
	}
}
