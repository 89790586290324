@import "../../../data/styles.css";

.articles-wrapper {
    display: flex;
    flex-wrap: wrap; /* Allows cards to wrap on smaller screens */
    justify-content: center; /* Center align articles */
}

.articles-article {
    flex: 1 1 calc(33.333% - 20px); /* Responsive flexbox for cards */
    margin: 10px; /* Add some spacing between cards */
    min-width: 250px; /* Ensure a minimum width for small devices */
}

.articles-article:hover {
    background: var(--bg-secondary);
    opacity: 1;
    transition: background-color 0.3s ease-in-out;
}

.article {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    border-radius: 10px; /* Added border radius for better aesthetics */
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); Subtle shadow */
    overflow: hidden; /* Ensure child elements don’t overflow */
}

.article a {
    text-decoration: none;
}

.article-left-side {
    width: 100%; /* Adjust to full width on smaller screens */
}

.article-right-side {
    flex-basis: 100%; /* Adjust to full width on smaller screens */
    mix-blend-mode: normal;
    border-radius: 10px;
    opacity: 0.8;
    padding: 20px; /* Adjusted padding */
}

/* .article-right-side:hover {
    background: #fafafa;
    opacity: 1;
    transition: background-color 0.3s ease-in-out;
} */

.article-date {
    font-size: 14px;
    color: var(--tertiary-color);
}

.article-title {
    color: var(--primary-color);
    font-size: 18px; /* Increased for better readability */
    font-weight: 600;
}

.article-description {
    padding-top: 5px;
    font-size: 14px; /* Increased for better readability */
    color: var(--secondary-color);
    line-height: 1.5; /* Improved line height for readability */
}

.article-link {
    padding-top: 10px;
    font-size: 14px;
    color: var(--link-color);
    font-weight: 700;
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
    .articles-article {
        flex: 1 1 calc(50% - 20px); /* 2 cards per row on tablet */
    }
}

@media (max-width: 768px) {
    .articles-article {
        flex: 1 1 100%; /* 1 card per row on mobile */
    }

    .article-title {
        font-size: 16px; /* Adjust font size for mobile */
    }

    .article-description {
        font-size: 12px; /* Adjust font size for mobile */
    }
}

@media (max-width: 480px) {
    .articles-logo-container {
        text-align: center; /* Center the logo on small screens */
    }

    .articles-title {
        font-size: 20px; /* Adjust title size */
    }

    .articles-subtitle {
        font-size: 14px; /* Adjust subtitle size */
    }
}
