.page-content {
	overflow-x: hidden;
	/* Prevent horizontal scrolling */
	width: 100vw;
	/* Ensure the content stays within the viewport width */
}
.articles-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.articles-logo {
	display: flex;
	position: fixed;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 3.8vh;
	z-index: 999;
}

.articles-main-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.articles-container {
	display: flex;
	flex-direction: column;
}

.articles-wrapper {
	padding-top: 50px;
	padding-bottom: 50px;
	display: flex;
	flex-direction: column;
}

.articles-article {
	padding-top: 15px;
	padding-left: 35px;
	padding-bottom: 20px;
	border-left: 2px solid #f4f4f5;
	width: 100%;
	box-sizing: border-box;
}

@media (max-width: 600px) {
	.articles-main-container {
		padding-top: 60px;
		padding-left: 10px;
			padding-right: 10px;
}
}
