@import "/src/data/styles.css";

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

:root {
  --bg-main: white;
  --bg-secondary: #fafafa;
  --color-primary: #27272a;
  --color-secondary: #65656d;
  --border-color: #f4f4f5;
  --scrollbar-track: #f1f1f1;
  --scrollbar-thumb: #383636;
  --scrollbar-thumb-hover: #000000;
}


[data-theme="dark"] {
  --bg-main: #1a1a1a;
  --bg-secondary: #2a2a2a;
  --color-primary: #e4e4e7;
  --color-secondary: #a1a1aa;
  --border-color: #3f3f46;
  --scrollbar-track: #2a2a2a;
  --scrollbar-thumb: #4a4a4a;
  --scrollbar-thumb-hover: #5a5a5a;
}

body {
  background-color: var(--bg-main);
  color: var(--color-primary);
  transition: background-color 0.3s ease, color 0.3s ease;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover);
}

.page-content {
  background-color: var(--bg-main);
  position: relative;
}

.page-content:before,
.page-content:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: calc((100% - 1200px) / 2);
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
}

.page-content:before {
  left: 0;
  z-index: 1;
}

.page-content:after {
  right: 0;
  z-index: 1;
}

.content-wrapper {
  max-width: 1000px;
  margin: 0 auto;
}

.title {
  color: var(--color-primary);
  font-family: var(--secondary-font);
  font-size: 45px;
  font-weight: 700;
  width: 70%;
}

.subtitle {
  padding-top: 10px;
  color: var(--color-secondary);
  font-size: 16px;
  line-height: 28px;
  width: 70%;
  margin-top: 25px;
}

.page-footer {
  position: block;
}

.page-footer::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border-top: 2px solid var(--border-color);
}

@media (max-width: 1270px) {
  .page-content:before,
  .page-content:after {
    width: calc((100% - 90%) / 2);
  }

  .content-wrapper {
    max-width: 90%;
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media (max-width: 1024px) {
  .page-content:before,
  .page-content:after {
    width: calc((100% - 950px) / 2);
  }

  .content-wrapper {
    max-width: 980px;
  }
}

@media (max-width: 800px) {
  .content-wrapper {
    max-width: 90%;
    margin: 0 auto;
    padding: 0 10px;
  }

  .page-content:before,
  .page-content:after {
    display: none;
  }

  .title {
    width: 100%;
    justify-content: center;
    display: flex;
  }

  .subtitle {
    width: 100%;
  }
}