@import "../../../data/styles.css";

.project {
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	height: 100%;
}

.project a {
	text-decoration: none;
}

.project:hover {
	background: var(--bg-secondary);
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.project:hover .project-link {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.project-container {
	height: 100%;
	width: 100%	;
  }
  

.project-logo {
	width: 30px;
}

.project-logo img {
	width: 100%;
}

.project-title {
	font-size: 1.5rem;
	/* font-family: var(--secondary-font); */
	color: var(--primary-color);
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 1em;
	font-weight: 700;
}

.project-description {
	color: var(--secondary-color);
	font-size: 0.9rem; /* Smaller size for description */
	line-height: 1.4; /* Adjust line height for better readability */
	margin-bottom: 15px;
}

.project-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	padding-top: 20px;
}

.project-link-icon {
	padding-left: 5px;
	font-size: 13px;
}

.project-link-text {
	padding-left: 20px;
	font-weight: 700;
}

@media (max-width: 600px) {
	.all-projects-project {
		flex: 1 1 calc(100% - 20px);
	}

	.project-title {
		font-size: 1.2rem; /* Further smaller title on small screens */
	}

	.project-description {
		font-size: 0.9rem; /* Further smaller size for description on small screens */
	}
}

@media (max-width: 900px) {
	.all-projects-project {
		flex: 1 1 calc(50% - 20px);
	}

	.project-title {
		font-size: 1.4rem; /* Slightly smaller title on medium screens */
	}
}