.all-projects-container {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	padding-top: 30px;
	padding-bottom: 30px;
	margin-left: -10px;
	margin-right: -10px;
}

.all-projects-project {
	flex: 1 1 calc(33.333% - 20px);
	box-sizing: border-box;
	padding: 10px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.project {
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: var(--bg-main);
	border-radius: 20px;
	padding: 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 900px) {
	.all-projects-project {
		flex: 1 1 calc(50% - 20px);
	}
}

@media (max-width: 600px) {
	.all-projects-project {
		flex: 1 1 calc(100% - 20px);
	}
}
