.page-content {
	background-color: var(--bg-main);
	color: var(--color-primary);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-x: hidden;
		/* Prevent horizontal scrolling */
		width: 100vw;
		/* Ensure the content stays within the viewport width */
  }
  
  .contact-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
  }
  
  .contact-logo {
	display: flex;
	position: fixed;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 3.5vh;
	z-index: 999;
  }
  
  .contact-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 100px;
		/* padding: 0 20px; */
	max-width: 800px;
	width: 100%;
  }
  .form-title{
	margin-top: 0%;
	margin-bottom: 0%;
  }
  
  .contact-title {
	font-size: 2rem;
	font-weight: bold;
	margin-bottom: 20px;
  }
  
  .contact-subtitle {
	width: 100% !important;
	font-size: 1rem;
	line-height: 1.6;
	margin-bottom: 40px;
	justify-content: center;
  }
  
  .socials-container {
	display: flex;
	flex-direction: row;
	margin-top: 80px;
	align-items: flex-start;
  }
  
  .contact-socials {
	flex: 1;
	display: flex;
	justify-content: flex-start;
	align-items: center;
  }
  
  .form-container {
	flex: 2;
	display: flex;
	justify-content: center;
  }
  
  .contact-form {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 500px;
	gap: 15px;
	margin-bottom: 10%;
  }
  
  .contact-form input,
  .contact-form textarea {
	padding: 10px;
	border: 1px solid var(--border-color);
	border-radius: 5px;
	background-color: var(--bg-secondary);
	color: var(--color-primary);
  }
  
  .contact-form input::placeholder,
  .contact-form textarea::placeholder {
	color: var(--color-secondary);
  }
  
  .contact-form button {
	padding: 10px 15px;
	border: none;
	border-radius: 5px;
	background-color: var(--color-primary);
	color: var(--bg-main);
	cursor: pointer;
	transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
	background-color: var(--color-secondary);
  }
  
  .page-footer {
	margin-top: auto;
	width: 100%;
  }
  
  @media (max-width: 600px) {
	/* .contact-container {
					padding-top: 20px;
					} */
  
	.socials-container {
	  flex-direction: column;
	  align-items: center;
	}
  
	.contact-form {
	  width: 90%;
	}
				.contact-subtitle {
					text-align: justify;
				}
  }